body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin-top: 75px;
  margin-left: 175px;
  padding: 1%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Recipe Ingredient component */
div[role='region'] > div{
  flex-direction: column;
}

@font-face {
  font-family: GibsonLight;
  src: url(./fonts/gibson_light.woff);
}

@font-face {
  font-family: GibsonBoldItalic;
  src: url(./fonts/gibson_bold_italic.woff);
}

@font-face {
  font-family: GibsonRegular;
  src: url(./fonts/gibson_regular.woff);
}

@font-face {
  font-family: GibsonBold;
  src: url(./fonts/gibson_bold.woff);
}

@font-face {
  font-family: GibsonSemiBold;
  src: url(./fonts/gibson_semi_bold.woff);
}

@font-face {
  font-family: GibsonSemiBoldItalic;
  src: url(./fonts/gibson_semi_bold_italic.woff);
}

@font-face {
  font-family: GibsonItalic;
  src: url(./fonts/gibson_italic.woff);
}